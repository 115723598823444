export interface ModuleType {
  readonly [key: string]: React.ComponentType<unknown>;
}

export enum Module {
  MOBILE = "mobile",
  DESKTOP = "desktop",
  ADMIN = "admin",
  BRIDGE = "bridge", // Shared resources between mobile and desktop
}

export enum PathParams {
  ASSET_ID = ":assetId",
  ANNOTATION_ID = ":annotationId",
}

export enum RouteTitles {
  SELECT_LANGUAGE = "app:PAGE_TITLE_SELECT_LANGUAGE",
  MORE = "app:PAGE_TITLE_MORE",
  INSPECTION = "app:PAGE_TITLE_INSPECTION",
  HOME = "app:PAGE_TITLE_HOME",
  NEW_PHOTO = "app:PAGE_TITLE_NEW_PHOTO",
  PHOTO = "app:PAGE_TITLE_PHOTO",
  LIST_INSPECTIONS = "app:PAGE_TITLE_LIST_INSPECTIONS",
  NEW_ASSET = "app:PAGE_TITLE_NEW_ASSET",
  EDIT_ASSET = "app:PAGE_TITLE_EDIT_ASSET",
  NEW_BOUNDING_BOX = "app:PAGE_TITLE_NEW_BOUNDING_BOX",
  USERS = "app:PAGE_TITLE_USERS",
  AUDIT = "app:PAGE_TITLE_AUDIT",
}
