import { IonButton, IonCard, IonCardContent, IonCardTitle, IonIcon } from "@ionic/react";
import { useTranslation } from "@mind-foundry/mf-ui-core";
import { alertCircle, checkmarkCircle, closeOutline, informationCircle, warning } from "ionicons/icons";
import { CustomContentProps, SnackbarContent, useSnackbar } from "notistack";
import React, { forwardRef, useCallback, useMemo } from "react";
import styled, { css } from "styled-components";

interface NotificationProps extends CustomContentProps {
  readonly id: string | number;
  readonly title: string;
  readonly message: string;
  readonly variant: "success" | "error" | "warning" | "info";
}

export const Notification = forwardRef<HTMLDivElement, NotificationProps>(
  function Notification({ id, title, message, variant }, ref) {
    const { t } = useTranslation();
    const { closeSnackbar } = useSnackbar();

    const handleDismiss = useCallback(() => {
      closeSnackbar(id);
    }, [id, closeSnackbar]);

    const getIcon = useMemo(() => {
      switch (variant) {
        case "success":
          return checkmarkCircle;
        case "error":
          return alertCircle;
        case "warning":
          return warning;
        case "info":
          return informationCircle;
        default:
          return checkmarkCircle;
      }
    }, [variant]);

    const getTitle = useMemo(() => {
      if (title) {
        return title;
      }

      switch (variant) {
        case "success":
          return t("app:SUCCESS");
        case "error":
          return t("app:ERROR");
        case "warning":
          return t("app:WARNING_EXCL");
        case "info":
          return t("app:INFO");
        default:
          return t("app:SUCCESS");
      }
    }, [title, variant, t]);

    return (
      <NotificationStyled ref={ref} className={variant}>
        <div className="notification-icon-container">
          <IonIcon icon={getIcon} className="notification-icon" />
        </div>
        <IonCard className="notification-content">
          <IonCardTitle className="notification-title">{getTitle}</IonCardTitle>
          <IonCardContent className="notification-message">{message}</IonCardContent>
        </IonCard>
        <div className="button-container">
          <IonButton
            onClick={handleDismiss}
            fill="clear"
            className="close-button"
          >
            <IonIcon icon={closeOutline} />
          </IonButton>
        </div>
      </NotificationStyled>
    );
  },
);

const variantColours = {
  success: "var(--ion-color-success)",
  error: "var(--ion-color-danger)",
  warning: "var(--ion-color-warning)",
  info: "var(--ion-color-step-300)",
};

const generateStyles = (colours: Record<string, string>) => {
  const colourEntries = Object.entries(colours);

  return colourEntries.map(
    ([name, colour]) =>
      css`
      &.${name} {
        border-left: 6px solid ${colour};

        .notification-icon-container {
          .notification-icon {
            color: ${colour};
          }
        }
      }
    `,
  );
};

const NotificationStyled = styled(SnackbarContent)`
  display: flex;
  height: 100%;
  justify-content: space-between;
  align-items: center;
  flex-wrap: nowrap;
  gap: 12px;
  padding: 8px 0 8px 12px;
  border-radius: 4px;
  background-color: var(--ion-color-primary-contrast);
  box-shadow: 0 2px 4px rgb(0 0 0 / 10%);

  ${generateStyles(variantColours)}

  .notification-icon-container {
    display: flex;
    padding-top: 4px;
    align-self: baseline;

    .notification-icon {
      border-radius: 50%;
      font-size: 2em;
    }
  }

  .notification-content {
    flex-grow: 1;
    max-width: 300px;
  }

  .notification-title {
    font-weight: bold;
    color: var(--ion-color-step-300);
    margin-bottom: 0.25em;
  }

  .notification-message {
    padding: 0;
  }

  .button-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    border-left: 1px solid var(--ion-color-step-900);

    &::part(native) {
      --background: transparent;
    }

    .button:hover {
      background-color: transparent;
    }

    .close-button {
      height: 100%;
      color: var(--ion-color-step-300);
    }
  }
`;
