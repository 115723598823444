import { lazy } from "react";
import { Module, ModuleType } from "./types";

export const lazyImport = (module: Module, path: string, parentPath?: string) => {
  return lazy(() => {
    if (parentPath) {
      return import(`../modules/${module}/pages/${parentPath}/pages/${path}/${path}.tsx`).then((module) => {
        const component = (module as ModuleType)[path];
        return { default: component };
      });
    }

    return import(`../modules/${module}/pages/${path}/${path}.tsx`).then((module) => {
      const component = (module as ModuleType)[path.split("/").pop() as string];
      return { default: component };
    });
  });
};
