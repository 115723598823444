import { Role } from "@models/assets";
import { RouteComponentProps } from "react-router-dom";
import { routePaths as adminRoutePaths } from "./modules/admin/types";
import { routePaths as desktopRoutePaths } from "./modules/desktop/types";
import { routePaths as mobileRoutePaths } from "./modules/mobile/types";

export interface RouteConfig {
  readonly path: typeof Routes[keyof typeof Routes];
  readonly component: React.ComponentType<RouterProps>;
  readonly exact?: boolean;
  readonly routes?: ReadonlyArray<RouteConfig>;
  readonly auth?: ReadonlyArray<Role>;
  readonly meta?: {
    readonly title: string;
  };
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type RouterProps = RouteComponentProps<any> & {
  readonly meta?: RouteConfig["meta"];
};

export const Routes = {
  BASE: "/",
  ...desktopRoutePaths,
  ...mobileRoutePaths,
  ...adminRoutePaths,
} as const;
