import { PathParams } from "@router/types";

const DESKTOP_APP_BASE = "/desktop";

export const routePaths = {
  DESKTOP_APP: DESKTOP_APP_BASE,
  DESKTOP_HOME: `${DESKTOP_APP_BASE}/home`,
  DESKTOP_NEW_ASSET: `${DESKTOP_APP_BASE}/new-asset`,
  DESKTOP_EDIT_ASSET: `${DESKTOP_APP_BASE}/edit-asset/${PathParams.ASSET_ID}`,
  DESKTOP_MORE_PAGE: `${DESKTOP_APP_BASE}/more`,
  DESKTOP_INSPECTION_LAYER: `${DESKTOP_APP_BASE}/inspection/${PathParams.ASSET_ID}`,
  DESKTOP_INSPECTION_PAGE: `${DESKTOP_APP_BASE}/inspection/${PathParams.ASSET_ID}/overview`,
  DESKTOP_NEW_PHOTO: `${DESKTOP_APP_BASE}/inspection/${PathParams.ASSET_ID}/new-photo`,
  DESKTOP_NEW_PHOTO_360: `${DESKTOP_APP_BASE}/inspection/${PathParams.ASSET_ID}/new-photo-360`,
  DESKTOP_PHOTO: `${DESKTOP_APP_BASE}/inspection/${PathParams.ASSET_ID}/${PathParams.ANNOTATION_ID}`,
  DESKTOP_SELECT_LANGUAGE: `${DESKTOP_APP_BASE}/language`,
} as const;
