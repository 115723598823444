import { useTranslation } from "@mind-foundry/mf-ui-core";
import { useEffect, useMemo } from "react";

export function LanguageFontSelector() {
  const { language } = useTranslation();
  const font = useMemo(() => {
    switch (language) {
      case "ja":
        return "メイリオ, Meiryo, \"ＭＳ Pゴシック\", \"Hiragino Sans GB\", \"Microsoft YaHei\", Arial, sans-serif";
      case "en":
        return "Arial, sans-serif";
      default:
        return "Arial, sans-serif";
    }
  }, [language]);

  useEffect(() => {
    // Done as a useEffect because I was unable to convince styled-components to update the ionic fonts
    const root = document.documentElement;
    root.style.setProperty("--ion-default-font", font);
  }, [font]);

  return null;
}
