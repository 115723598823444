import { MoreMetaProps } from "@/modules/bridge/pages/MorePage/MorePage";
import { SelectLanguageMetaProps } from "@/modules/bridge/pages/SelectLanguage/SelectLanguage";
import { Role } from "@models/assets";
import { RouteConfig } from "@router/routeTypes";
import { Module, RouteTitles } from "@router/types";
import { lazyImport } from "@router/utils";

import { routePaths } from "./types";

const createModuleImport = (...args: [string, ...string[]]) => {
  return lazyImport(Module.ADMIN, ...args);
};

const components = {
  users: createModuleImport("UsersPage"),
  audit: createModuleImport("AuditPage"),
  more: lazyImport(Module.BRIDGE, "MorePage"),
  selectLanguage: lazyImport(Module.BRIDGE, "SelectLanguage"),
};

export const routes: ReadonlyArray<RouteConfig> = [
  {
    path: routePaths.ADMIN_USERS,
    component: components.users,
    auth: [Role.ADMIN, Role.SUPERADMIN],
    meta: {
      title: RouteTitles.USERS,
    },
  },
  {
    path: routePaths.ADMIN_AUDIT,
    component: components.audit,
    auth: [Role.ADMIN, Role.SUPERADMIN],
    meta: {
      title: RouteTitles.AUDIT,
    },
  },
  {
    path: routePaths.ADMIN_MORE_PAGE,
    component: components.more,
    auth: [Role.ADMIN, Role.SUPERADMIN],
    meta: {
      title: RouteTitles.MORE,
      selectLanguageRoutePath: routePaths.ADMIN_SELECT_LANGUAGE,
      showBackButton: false,
    },
  } as RouteConfig & { meta: MoreMetaProps },
  {
    path: routePaths.ADMIN_SELECT_LANGUAGE,
    component: components.selectLanguage,
    auth: [Role.ADMIN, Role.SUPERADMIN],
    meta: {
      title: RouteTitles.SELECT_LANGUAGE,
      showBackButton: false,
    },
  } as RouteConfig & { meta: SelectLanguageMetaProps },
];
