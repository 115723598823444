/* eslint-disable camelcase */
import { PreCachedImages } from "@common/images";
import { Id } from "@common/localStorage/common";

import { Annotation } from "./annotations";
import { ItemWithId } from "./base";

export enum Role { // same as backend/src/models.py
  NONE = "none",
  SUPERADMIN = "superadmin",
  ADMIN = "admin",
  BRIDGE_INSPECTOR = "bridge_inspector",
  BRIDGE_ENGINEER = "bridge_engineer",
}

export interface AppUser {
  readonly id: string;
  readonly email: string;
  readonly blocked: boolean;
  readonly language?: string;
  readonly last_login?: Date;
  readonly last_update?: Date;
  readonly roles: ReadonlyArray<Role>;
}

// Values correspond to translation message keys in app.json
export enum AssetType { // same as backend/src/models.py
  BOX_CULVERT = "BOX_CULVERT",
  INTEGRAL_ABUTMENT_BRIDGE = "INTEGRAL_ABUTMENT_BRIDGE",
  SIMPLY_SUPPORTED_SINGLE_SPAN_BRIDGE = "SIMPLY_SUPPORTED_SINGLE_SPAN_BRIDGE",
  DOUBLE_SPAN_CONTINUOUS_BRIDGE = "DOUBLE_SPAN_CONTINUOUS_BRIDGE",
}

interface DraftAsset extends ItemWithId {
  readonly thumbnail: string;
  readonly address: string;
  readonly type: AssetType;
  readonly orientation: number;
  readonly latitude: number;
  readonly longitude: number;
}

export interface Asset extends DraftAsset {
  readonly inspection_id: Id;
  readonly created_at: Date;
  readonly created_by: string;
  readonly uploaded_at?: Date;
  readonly deleted: boolean;
  readonly last_updated?: Date;

  // bridge-specific fields
  readonly name?: string;
  readonly route_name?: string;
  readonly administrator_name?: string;
  readonly ground_conditions?: string;
  readonly alternative_route_availability?: number; // 1 = Easy - 10 = Hard
  readonly is_road_private?: boolean; // false = public, true = private
  readonly route_strategic_importance?: string;
  readonly construction_year?: number;
  readonly span_meters?: number;
  readonly width_meters?: number;
  readonly traffic_volume?: number;
  readonly serves_hospital?: boolean;
  readonly serves_school?: boolean;
  readonly deicing_salts_exposure?: boolean;
}

export interface DraftAssetData {
  readonly asset: Asset | null;
  readonly annotations: ReadonlyArray<Annotation>;
}

export interface AssetData extends DraftAssetData {
  readonly inspection_id: Id;
}

interface AssetTypeMetadata {
  readonly name: string;
  readonly image: string;
}

export const typeMetadata: Record<AssetType, AssetTypeMetadata> = {
  BOX_CULVERT: {
    name: "app:BOX_CULVERT",
    image: PreCachedImages.BOX_CULVERT,
  },
  INTEGRAL_ABUTMENT_BRIDGE: {
    name: "app:INTEGRAL_ABUTMENT_BRIDGE",
    image: PreCachedImages.INTEGRAL_SUPPORT,
  },
  DOUBLE_SPAN_CONTINUOUS_BRIDGE: {
    name: "app:DOUBLE_SPAN_CONTINUOUS_BRIDGE",
    image: PreCachedImages.SPAN_SUPPORT_CONTINUOUS,
  },
  SIMPLY_SUPPORTED_SINGLE_SPAN_BRIDGE: {
    name: "app:SIMPLY_SUPPORTED_SINGLE_SPAN_BRIDGE",
    image: PreCachedImages.SPAN_SUPPORT_SIMPLY_SUPPORTED,
  },
};
