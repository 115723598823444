import { MessageKey } from "@common/translation";
import { Routes } from "@router/routeTypes";
import { add, documentTextOutline, homeOutline, list, settingsSharp } from "ionicons/icons";
import { useMemo } from "react";
import { useLocation } from "react-router";

export interface TabType {
  readonly tab: typeof Routes[keyof typeof Routes];
  readonly icon: string;
  readonly label: MessageKey;
}

const EngineerMenuTabs: TabType[] = [
  {
    tab: Routes.DESKTOP_HOME,
    icon: homeOutline,
    label: "app:PAGE_TITLE_HOME",
  },
  {
    tab: Routes.DESKTOP_NEW_ASSET,
    icon: add,
    label: "app:PAGE_TITLE_NEW_ASSET",
  },
  {
    tab: Routes.DESKTOP_MORE_PAGE,
    icon: settingsSharp,
    label: "app:PAGE_TITLE_MORE",
  },
];

const InspectorMenuTabs: TabType[] = [
  {
    tab: Routes.MOBILE_HOME,
    icon: homeOutline,
    label: "app:PAGE_TITLE_HOME",
  },
  {
    tab: Routes.MOBILE_LIST_INSPECTIONS,
    icon: list,
    label: "app:PAGE_TITLE_LIST_INSPECTIONS",
  },
  {
    tab: Routes.MOBILE_MORE_PAGE,
    icon: settingsSharp,
    label: "app:PAGE_TITLE_MORE",
  },
];

const AdminMenuTabs: TabType[] = [
  {
    tab: Routes.ADMIN_USERS,
    icon: homeOutline,
    label: "app:PAGE_TITLE_USERS",
  },
  {
    tab: Routes.ADMIN_AUDIT,
    icon: documentTextOutline,
    label: "app:PAGE_TITLE_AUDIT",
  },
  {
    tab: Routes.ADMIN_MORE_PAGE,
    icon: settingsSharp,
    label: "app:PAGE_TITLE_MORE",
  },
];

export function useMenuTabs(): TabType[] {
  const location = useLocation();

  return useMemo(() => {
    if (location.pathname.includes(Routes.DESKTOP_APP)) {
      return EngineerMenuTabs;
    }

    if (location.pathname.includes(Routes.MOBILE_APP)) {
      return InspectorMenuTabs;
    }

    if (location.pathname.includes(Routes.ADMIN_APP)) {
      return AdminMenuTabs;
    }

    return [];
  }, [location]);
}
