import { PathParams } from "@router/types";

const MOBILE_APP_BASE = "/mobile";

export const routePaths = {
  MOBILE_APP: MOBILE_APP_BASE,
  MOBILE_HOME: `${MOBILE_APP_BASE}/home`,
  MOBILE_INSPECTION_PAGE: `${MOBILE_APP_BASE}/inspection/${PathParams.ASSET_ID}/overview`,
  MOBILE_MORE_PAGE: `${MOBILE_APP_BASE}/more`,
  MOBILE_SELECT_LANGUAGE_PAGE: `${MOBILE_APP_BASE}/select-language`,
  MOBILE_NEW_PHOTO: `${MOBILE_APP_BASE}/inspection/${PathParams.ASSET_ID}/new-photo`,
  MOBILE_PHOTO: `${MOBILE_APP_BASE}/inspection/${PathParams.ASSET_ID}/${PathParams.ANNOTATION_ID}`,
  MOBILE_LIST_INSPECTIONS: `${MOBILE_APP_BASE}/list-inspections`,
  MOBILE_NEW_BOUNDING_BOX:
    `${MOBILE_APP_BASE}/inspection/${PathParams.ASSET_ID}/${PathParams.ANNOTATION_ID}/new-bounding-box`,
} as const;
