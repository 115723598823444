import { useGetCurrentUser } from "@common/requests/user";
import { useTranslation } from "@mind-foundry/mf-ui-core";
import React, { useCallback, useEffect, useMemo } from "react";
import { matchPath, Redirect, Route, Switch } from "react-router-dom";

import { AuthError } from "@/ErrorPage";
import { routes as AdminRoutes } from "./modules/admin/routes";
import { routes as desktopRoutes } from "./modules/desktop/routes";
import { routes as mobileRoutes } from "./modules/mobile/routes";
import { RouteConfig, RouterProps, Routes } from "./routeTypes";
import { PathParams } from "./types";

function RedirectComponent(): React.ReactElement {
  const { data: user } = useGetCurrentUser();

  if (user?.roles) {
    const firstAccessibleRoute = routeConfig.find((route) => {
      return (
        route.path !== Routes.BASE
        && (!route.auth || route.auth.some(role => user?.roles?.includes(role)))
      );
    });

    if (firstAccessibleRoute) {
      return <Redirect to={firstAccessibleRoute.path} />;
    }
  }

  return <AuthError />;
}

const routeConfig: ReadonlyArray<RouteConfig> = [
  {
    path: Routes.BASE,
    component: RedirectComponent,
    exact: true,
  },
  ...desktopRoutes,
  ...mobileRoutes,
  ...AdminRoutes,
];

type FindMatchRouteReturn =
  | RouteConfig & {
    readonly params: Record<string, PathParams>;
  }
  | null;

function findMatchingRoute(pathname: string, routes = routeConfig): FindMatchRouteReturn {
  for (const route of routes) {
    const match = matchPath(pathname, {
      path: route.path,
      exact: !!route.exact,
      strict: false,
    });

    // If match is found, return this route or look inside child routes
    if (match) {
      if (route.routes && route.routes.length > 0) {
        const matchedChildRoute = findMatchingRoute(pathname, route.routes);

        if (matchedChildRoute) {
          return matchedChildRoute;
        }
      }

      return {
        params: match.params,
        ...route,
      };
    }
  }

  return null; // No match found
}

function findMatchingRouteTitle(
  pathname: string,
  t: (key: string, args?: Record<string, string>) => string,
): string {
  const matchedRoute = findMatchingRoute(pathname);

  // Add new cases if special behaviour is required to determine page title

  switch (matchedRoute?.meta?.title) {
    default:
      return t(matchedRoute?.meta?.title ?? "");
  }
}

function ChildRoute({ path, exact, component, meta, routes, auth }: RouteConfig) {
  const { t } = useTranslation();
  const renderFunction = useCallback((routeProps: RouterProps) => {
    return React.createElement(component, { ...routeProps, meta });
  }, [component, meta]);

  useEffect(() => {
    if (meta?.title) {
      document.title = findMatchingRouteTitle(path, t);
    }
  }, [meta, path, t]);

  const renderRoutes = (props: RouterProps) => {
    const isLayerComponent = routes && routes.length > 0;

    const render = () => {
      return (
        React.createElement(
          component,
          props,
          <Switch>
            {routes?.map((childRoute) => (
              <ChildRoute
                key={childRoute.path}
                {...childRoute}
              />
            ))}
            <Route path="*">
              <RedirectComponent />
            </Route>
          </Switch>,
        )
      );
    };

    return (
      <>
        {isLayerComponent
          ? (
            <Route
              path={path}
              render={render}
            />
          )
          : (
            renderFunction(props)
          )}
      </>
    );
  };

  const { data: user } = useGetCurrentUser();

  // Redirect to the first accessible route if the user is not authorized
  if (auth && user?.roles && !auth.some(role => user?.roles?.includes(role))) {
    return <RedirectComponent />;
  }

  return (
    <Route
      key={path}
      path={path}
      exact={exact ?? false}
      render={renderRoutes}
    />
  );
}

export function useRouter(routes = routeConfig): React.ReactElement {
  return useMemo(
    () => (
      <Switch>
        {routes.map((route) => <ChildRoute key={route.path} {...route} />)}
        <Route path="*">
          <RedirectComponent />
        </Route>
      </Switch>
    ),
    [routes],
  );
}
