import { MoreMetaProps } from "@/modules/bridge/pages/MorePage/MorePage";
import { SelectLanguageMetaProps } from "@/modules/bridge/pages/SelectLanguage/SelectLanguage";
import { Role } from "@models/assets";
import { RouteConfig } from "@router/routeTypes";
import { Module, RouteTitles } from "@router/types";
import { lazyImport } from "@router/utils";
import { lazy } from "react";

import { routePaths } from "./types";

const createModuleImport = (...args: [string, ...string[]]) => {
  return lazyImport(Module.DESKTOP, ...args);
};

const components = {
  home: createModuleImport("HomePage"),
  inspection: createModuleImport("InspectionPage", "InspectionLayer"),
  newPhoto: createModuleImport("NewPhotoPage", "InspectionLayer"),
  photo: createModuleImport("AnnotationPage", "InspectionLayer"),
  newAsset: lazy(() => {
    return import(`@/modules/${Module.DESKTOP}/pages/EditAssetPages/EditNewAssetPage.tsx`).then((module) => {
      const component = (module as { EditNewAssetPage: React.ComponentType }).EditNewAssetPage;
      return { default: component };
    });
  }),
  editAsset: lazy(() => {
    return import(`@/modules/${Module.DESKTOP}/pages/EditAssetPages/EditExistingAssetPage.tsx`).then((module) => {
      const component = (module as { EditExistingAssetPage: React.ComponentType }).EditExistingAssetPage;
      return { default: component };
    });
  }),
  more: lazyImport(Module.BRIDGE, "MorePage"),
  selectLanguage: lazyImport(Module.BRIDGE, "SelectLanguage"),
  inspectionLayer: createModuleImport("InspectionLayer"),
};

export const routes: ReadonlyArray<RouteConfig> = [
  {
    path: routePaths.DESKTOP_HOME,
    component: components.home,
    auth: [Role.BRIDGE_ENGINEER],
    meta: {
      title: RouteTitles.HOME,
    },
  },
  {
    path: routePaths.DESKTOP_MORE_PAGE,
    component: components.more,
    auth: [Role.BRIDGE_ENGINEER],
    meta: {
      title: RouteTitles.MORE,
      selectLanguageRoutePath: routePaths.DESKTOP_SELECT_LANGUAGE,
      showBackButton: false,
    },
  } as RouteConfig & { meta: MoreMetaProps },
  {
    path: routePaths.DESKTOP_NEW_ASSET,
    component: components.newAsset,
    auth: [Role.BRIDGE_ENGINEER],
    meta: {
      title: RouteTitles.NEW_ASSET,
    },
  },
  {
    path: routePaths.DESKTOP_EDIT_ASSET,
    component: components.editAsset,
    auth: [Role.BRIDGE_ENGINEER],
    meta: {
      title: RouteTitles.EDIT_ASSET,
    },
  },
  {
    path: routePaths.DESKTOP_SELECT_LANGUAGE,
    component: components.selectLanguage,
    auth: [Role.BRIDGE_ENGINEER],
    meta: {
      title: RouteTitles.SELECT_LANGUAGE,
      showBackButton: false,
    },
  } as RouteConfig & { meta: SelectLanguageMetaProps },
  {
    path: routePaths.DESKTOP_INSPECTION_LAYER,
    component: components.inspectionLayer,
    auth: [Role.BRIDGE_ENGINEER],
    routes: [
      {
        path: routePaths.DESKTOP_INSPECTION_PAGE,
        component: components.inspection,
        meta: {
          title: RouteTitles.INSPECTION,
        },
      },
      {
        path: routePaths.DESKTOP_NEW_PHOTO,
        component: components.newPhoto,
        meta: {
          title: RouteTitles.NEW_PHOTO,
        },
      },
      {
        path: routePaths.DESKTOP_PHOTO,
        component: components.photo,
        meta: {
          title: RouteTitles.PHOTO,
        },
      },
    ],
  },
];
