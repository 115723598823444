/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";
/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";
/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/display.css";
/* Theme variables */
import "./theme/variables.css";

import { useGetCurrentUser } from "@common/requests/user";

import { LanguageFontSelector } from "@components/LanguageFontSelector";
import { Notification } from "@components/Notification";
import { Spinner } from "@components/Spinner";
import { useConfig } from "@config";
import { IonApp, IonRouterOutlet, IonTabs, setupIonicReact } from "@ionic/react";
import { IonIcon, IonLabel, IonTabBar, IonTabButton } from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";
import { useTranslation } from "@mind-foundry/mf-ui-core";
import { TabType, useMenuTabs } from "@router/menuTabs";
import { APIProvider } from "@vis.gl/react-google-maps";
import { AxiosError } from "axios";
import { SnackbarProvider } from "notistack";
import React, { Suspense } from "react";
import styled from "styled-components";

import { useRouter } from "@router/router";
import { AuthError, ErrorPage } from "./ErrorPage";

setupIonicReact();

export function App(): React.ReactElement {
  const config = useConfig();
  const { data: user, error } = useGetCurrentUser();
  const router = useRouter();

  const { t } = useTranslation();

  if (error) {
    if ((error as AxiosError).response?.status === 403) {
      return <AuthError />;
    }

    return <ErrorPage errorMessage={t("app:UNKNOWN_ERROR")} />;
  }

  if (!user) {
    return <Spinner spinnerSize="large" />;
  }

  return (
    <APIProvider apiKey={config.gmaps.key}>
      <IonApp data-test-id="bridge-app">
        <LanguageFontSelector />
        <SnackbarProvider
          Components={{
            success: Notification,
            error: Notification,
            warning: Notification,
            info: Notification,
          }}
          variant="success"
          anchorOrigin={{ horizontal: "right", vertical: "top" }}
          autoHideDuration={3500}
          preventDuplicate={true}
        >
          <AppStyled>
            <IonReactRouter>
              <IonTabs className="page-content">
                <IonRouterOutlet>
                  <Suspense fallback={<Spinner spinnerSize="large" />}>
                    {router}
                  </Suspense>
                </IonRouterOutlet>
                <MenuTabs />
              </IonTabs>
            </IonReactRouter>
          </AppStyled>
        </SnackbarProvider>
      </IonApp>
    </APIProvider>
  );
}

function MenuTabs(): React.ReactElement | null {
  const menuTabs = useMenuTabs();
  const { t } = useTranslation();

  if (!menuTabs.length) {
    return null;
  }

  return (
    <IonTabBar slot="bottom">
      {menuTabs.map((tab: TabType) => (
        <IonTabButton
          key={tab.tab}
          tab={tab.tab}
          href={tab.tab}
          data-test-id={`tab-button-${tab.tab}`}
        >
          <IonIcon icon={tab.icon} title={t(tab.label)} />
          <IonLabel className="tab-text">{t(tab.label)}</IonLabel>
        </IonTabButton>
      ))}
    </IonTabBar>
  );
}

const AppStyled = styled.div`
  ion-tab-bar {
    container-type: inline-size;

    @container (width <= 305px) {
      .tab-text {
        display: none;
      }
    }
  }

  @media only screen and (width >= 600px) {
    .page-content > ion-tabs {
      flex-direction: row-reverse !important;
    }

    ion-tab-bar {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      height: 100%;
      width: 80px;
      gap: 12px;
      padding: 16px 0 18px;

      ion-tab-button {
        height: auto;
        flex: 0;
        color: var(--ion-color-step-900);
        font-size: 0.95em;
        overflow: visible;
      }

      ion-icon {
        padding: 10px;
        font-size: 1.8em;
      }

      .tab-selected {
        color: var(--ion-color-step-900);
        opacity: 0.7;

        ion-icon {
          background-color: rgb(255 255 255 / 20%);
          border-radius: 6px;
        }
      }
    }
  }
`;
