import { MoreMetaProps } from "@/modules/bridge/pages/MorePage/MorePage";
import { SelectLanguageMetaProps } from "@/modules/bridge/pages/SelectLanguage/SelectLanguage";
import { Role } from "@models/assets";
import { RouteConfig, RouterProps } from "@router/routeTypes";
import { Module, RouteTitles } from "@router/types";
import { lazyImport } from "@router/utils";
import { routePaths } from "./types";

const createModuleImport = (...args: [string, ...string[]]) => {
  return lazyImport(Module.MOBILE, ...args);
};

const components = {
  home: createModuleImport("HomePage"),
  inspection: createModuleImport("InspectionPage"),
  newPhoto: createModuleImport("NewPhotoPage"),
  photo: createModuleImport("AnnotationPage"),
  inspectionList: createModuleImport("InspectionListPage"),
  newBoundingBox: createModuleImport("NewBoundingBoxPage"),
  more: lazyImport(Module.BRIDGE, "MorePage"),
  selectLanguage: createModuleImport("SelectLanguage"),
  newAsset: createModuleImport("NewAssetPage"),
};

export const routes: ReadonlyArray<RouteConfig> = [
  {
    path: routePaths.MOBILE_HOME,
    component: components.home,
    auth: [Role.BRIDGE_INSPECTOR],
    meta: {
      title: RouteTitles.HOME,
    },
  },
  {
    path: routePaths.MOBILE_INSPECTION_PAGE,
    component: components.inspection,
    auth: [Role.BRIDGE_INSPECTOR],
    meta: {
      title: RouteTitles.INSPECTION,
    },
  },
  {
    path: routePaths.MOBILE_MORE_PAGE,
    component: components.more,
    auth: [Role.BRIDGE_INSPECTOR],
    meta: {
      title: RouteTitles.MORE,
      selectLanguageRoutePath: routePaths.MOBILE_SELECT_LANGUAGE_PAGE,
      showBackButton: true,
    },
  } as RouteConfig & { meta: RouterProps["meta"] & MoreMetaProps },
  {
    path: routePaths.MOBILE_SELECT_LANGUAGE_PAGE,
    component: components.selectLanguage,
    auth: [Role.BRIDGE_INSPECTOR],
    meta: {
      title: RouteTitles.SELECT_LANGUAGE,
      showBackButton: true,
    },
  } as RouteConfig & { meta: RouterProps["meta"] & SelectLanguageMetaProps },
  {
    path: routePaths.MOBILE_NEW_PHOTO,
    component: components.newPhoto,
    auth: [Role.BRIDGE_INSPECTOR],
    meta: {
      title: RouteTitles.NEW_PHOTO,
    },
  },
  {
    path: routePaths.MOBILE_PHOTO,
    component: components.photo,
    auth: [Role.BRIDGE_INSPECTOR],
    exact: true,
    meta: {
      title: RouteTitles.PHOTO,
    },
  },
  {
    path: routePaths.MOBILE_LIST_INSPECTIONS,
    component: components.inspectionList,
    auth: [Role.BRIDGE_INSPECTOR],
    meta: {
      title: RouteTitles.LIST_INSPECTIONS,
    },
  },
  {
    path: routePaths.MOBILE_NEW_BOUNDING_BOX,
    component: components.newBoundingBox,
    auth: [Role.BRIDGE_INSPECTOR],
    meta: {
      title: RouteTitles.NEW_BOUNDING_BOX,
    },
  },
];
