import { IonSpinner } from "@ionic/react";
import React from "react";
import styled from "styled-components";

type SpinnerSize = "x-small" | "small" | "medium" | "large" | "x-large";

export function Spinner(
  {
    isFullPage = false,
    spinnerSize = "medium",
    cover = false,
  }: {
    readonly isFullPage?: boolean;
    readonly spinnerSize?: SpinnerSize;
    readonly cover?: boolean;
  },
): React.ReactElement {
  return (
    <SpinnerContainer
      className={`
        ${isFullPage ? "full-page-loader" : "component-loader"}
        ${spinnerSize}
        ${cover ? "cover" : ""}
      `}
    >
      <IonSpinner name="crescent" />
    </SpinnerContainer>
  );
}

const SpinnerContainer = styled.div`
  --color: rgb(148 148 148);

  &.full-page-loader {
    position: fixed;
    inset: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgb(255 255 255 / 80%);
    z-index: 9999;
  }

  &.component-loader {
    position: relative;
    top: -7.5%;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;

    &.cover {
      position: absolute;
      inset: 0;
      background-color: var(--background);
      z-index: 1;
    }
  }

  &.x-small ion-spinner {
    width: 25px;
    height: 25px;
  }

  &.small ion-spinner {
    width: 40px;
    height: 40px;
  }

  &.medium ion-spinner {
    width: 60px;
    height: 60px;
  }

  &.large ion-spinner {
    width: 100px;
    height: 100px;
  }

  &.x-large ion-spinner {
    width: 120px;
    height: 120px;
  }
`;
