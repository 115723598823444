import { updateApp } from "@common/updateApp";
import { useAuth, useTranslation } from "@mind-foundry/mf-ui-core";

import styled from "styled-components";

// Error page that doesn't require the ionic framework and is independant of modules
export function ErrorPage({
  errorMessage,
}: {
  readonly errorMessage: string;
}): React.ReactElement {
  const { t } = useTranslation();
  const { logout } = useAuth();

  return (
    <ErrorContent>
      <div className="error-container">
        <div className="header">
          <h1>{t("app:ERROR_PAGE_HEADER")}</h1>
        </div>
        <div className="content">
          <span className="error-icon">ⓘ</span>
          <div className="text">
            <h2>{errorMessage}</h2>
          </div>
          <div className="text">
            <h1>{t("app:AUTH_FAILED")}</h1>
          </div>
          <div className="button-container">
            <button
              type="button"
              className="action-button"
              onClick={logout}
            >
              {t("app:LOGOUT")}
            </button>
            <button
              type="button"
              className="action-button"
              onClick={updateApp}
            >
              {t("app:UPDATE_APP")}
            </button>
          </div>
        </div>
      </div>
    </ErrorContent>
  );
}

export function AuthError(): React.ReactElement {
  const { t } = useTranslation();

  return <ErrorPage errorMessage={t("app:ERROR_PAGE_GUIDANCE")} />;
}

const ErrorContent = styled.div`
  font-family: Roboto, sans-serif;
  padding: 16px;

  .error-container {
    display: flex;
    flex-direction: column;
    max-width: 600px;
    margin: 0 auto;
    padding-top: 15vh;
    gap: 24px;
    align-items: center;
  }

  .header {
    text-align: center;
  }

  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 40px;
  }

  .text {
    text-align: center;
  }

  .error-icon {
    font-size: 8em;
  }

  .button-container {
    display: flex;
    gap: 20px;
  }

  .action-button {
    padding: 12px 24px;
    font-size: 1em;
    font-weight: bold;
    color: #fff;
    background-color: #007bff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.3s ease;
  }

  .action-button:hover {
    background-color: #0056b3;
  }
`;
